<script setup>
import EventHome from "@/views/event/EventHome.vue";
import EventCreateVue from "@/views/event/EventCreate.vue";
import EventEditVue from "@/views/event/EventEdit.vue";
import SettingHome from "@/views/setting/SettingHome.vue";
import SettingModify from "@/views/setting/SettingModify.vue";

import { useUserStore } from "@/stores/user.js";

const userStore = useUserStore();
</script>

<template>
  <section>
    <div v-if="userStore.menu === 'home'">
      <div class="ttl-wrap">
        <h1>HOME</h1>
      </div>
    </div>
    <div v-if="userStore.menu === 'event'">
      <EventHome />
    </div>
    <div v-if="userStore.menu === 'eventCreate'">
      <EventCreateVue />
    </div>
    <div v-if="userStore.menu.startsWith('eventEdit')">
      <Suspense>
        <EventEditVue />
        <template #fallback> Loading... </template>
      </Suspense>
    </div>
    <div v-if="userStore.menu === 'setting'">
      <Suspense>
        <SettingHome />
        <template #fallback> Loading... </template>
      </Suspense>
    </div>
    <div v-if="userStore.menu === 'settingModify'">
      <SettingModify />
    </div>
  </section>
</template>
