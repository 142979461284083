//認証情報はこのオブジェクトで管理する。
import {
  signInWithEmailAndPassword,
  signOut,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";

export var Auth = {
  loggedIn: false,
  // ここはサーバで認証処理(login)を実行する。
  login: async function (auth, email, password) {
    // this.sending = true;
    console.log(email, password);
    let user = null;
    await signInWithEmailAndPassword(auth, email, password)
      .then((success) => {
        user = success.user;
        this.loggedIn = true;
      })
      .catch((error) => {
        alert("ログイン情報が間違っています。" + error);
        return null;
      })
      .finally(() => {
        // this.sending = false;
      });
    // TODO:サーバから取得したtokenをlocalstrageに保存するように変更が必要。
    // localStorage.token = Math.random().toString(36).substring(7);
    return user;
  },
  // ここはサーバで認証処理(logout)を実行する。
  logout: function (auth) {
    signOut(auth).catch((error) => {
      alert("ログアウト失敗：" + error);
      return false;
    });
  },
  // ユーザー登録 項目確認
  register: async function (auth, email, password, displayName) {
    if (!email || !password || !displayName) {
      alert("必須項目エラー");
      return;
    }

    await { email, password, displayName }.asign(email, password, displayName);
    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = credential.user;
      await updateProfile(user, { displayName });
      return user;
    } catch (error) {
      alert("登録情報が正しくありません。" + error.message);
      return null;
    }
  },
  // ユーザー登録
  asign: async function (auth, email, password, displayName) {
    try {
      const credential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = credential.user;
      await updateProfile(user, { displayName });
      return user;
    } catch (error) {
      alert("登録情報が正しくありません。" + error.message);
      return null;
    }
  },
};
