<script setup>
import { db, auth } from "@/firebase/firebaseConfig";
import { collection, doc, setDoc } from "firebase/firestore";
import { updateProfile } from "firebase/auth";
import { ref } from "vue";
import { useUserStore } from "@/stores/user.js";
import loadModal from "@/components/event/LoadingModal.vue";
const isLoading = ref(false);

const userStore = useUserStore();

const updateUserInfo = async () => {
  const userRef = collection(db, "users");
  try {
    setDoc(doc(userRef, userStore.id), {
      userName: userStore.displayName,
      organizerName: userStore.organizerName,
      represent: userStore.represent,
      tel: userStore.tel,
      email: userStore.email,
    });
  } catch (error) {
    console.error("Error updating user info:", error);
  }

  updateProfile(auth.currentUser, {
    displayName: userStore.displayName,
  })
    .then(() => {})
    .catch((error) => {
      console.error("Error updating user displayName:", error);
    });
};

async function handleClickRegister() {
  isLoading.value = true;
  await updateUserInfo();
  isLoading.value = false;
  userStore.menu = "setting";
}

function handleClickCancel() {
  userStore.setUserMenu("setting");
}
</script>

<template id="isSetting">
  <section id="isSetting" class="admin-setting-modify">
    <div class="ttl-wrap">
      <h1>編集</h1>
    </div>
    <form class="l-form" @submit.prevent="updateUserInfo">
      <div class="form-dtl">
        <label class="ttl">名前</label>
        <input
          id="displayName"
          v-model="userStore.displayName"
          type="text"
          placeholder="エーポップ 太郎"
        />
      </div>
      <div class="form-dtl">
        <label class="ttl">オーガナイザー名</label>
        <input
          id="organizerName"
          v-model="userStore.organizerName"
          type="text"
          placeholder="エーポップ"
        />
      </div>
      <div class="form-dtl">
        <label class="ttl">レペゼン</label>
        <input
          id="represent"
          v-model="userStore.represent"
          type="text"
          placeholder="Apoplanet"
        />
      </div>
      <div class="form-dtl">
        <label class="ttl">電話番号</label>
        <input
          id="tel"
          v-model="userStore.tel"
          type="text"
          placeholder="09012345678"
        />
      </div>
      <div class="form-dtl">
        <label class="ttl">メールアドレス</label>
        <input
          id="email"
          v-model="userStore.email"
          type="text"
          placeholder="apop@apopnanet.pop"
        />
      </div>
      <div class="l-btn is-col2">
        <button
          class="btn-secondary"
          type="button"
          @click="handleClickCancel()"
        >
          キャンセル
        </button>
        <button
          class="btn-primary"
          type="button"
          @click="handleClickRegister()"
        >
          登録する
        </button>
      </div>
      <Teleport to="body">
        <!-- <modal :show="showModal" @close="showModal = false">
          <template />
        </modal> -->
      </Teleport>
    </form>
    <loadModal :is-loading="isLoading" />
  </section>
</template>

<style lang="scss">
@import "@/assets/scss/page/adminSettingModify.scss";
</style>
