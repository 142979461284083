<script setup>
import { db } from "@/firebase/firebaseConfig";
import { collection, doc, setDoc, getDocs } from "firebase/firestore";
import { saveEventsImg } from "./functional/firebase";

import { ref, watch } from "vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { prefs } from "@/data/pref";

import { useEventStore } from "@/stores/event.js";
import { useUserStore } from "@/stores/user.js";

import eventModal from "@/components/event/LoadingModal.vue";

// store呼び出し
const userStore = useUserStore();
const eventStore = useEventStore();
eventStore.$reset();

const isCreate = ref(false);
const isLoading = ref(false);

// プレビュー表示用ref
const topPrevUrl1 = ref("");
const topPrevUrl2 = ref("");
const subPrevUrl1 = ref("");
const subPrevUrl2 = ref("");
const subPrevUrl3 = ref("");
const subPrevUrl4 = ref("");
const subPrevUrl5 = ref("");

watch(isCreate, () => {
  if (isCreate.value) {
    userStore.setUserMenu("event");
  }
});

const uploadFile = ($event, imgArea) => {
  if (!$event.target.files[0]) return;
  if (imgArea === "top1") {
    eventStore.topImg1 = $event.target.files[0];
    topPrevUrl1.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "top2") {
    eventStore.topImg2 = $event.target.files[0];
    topPrevUrl2.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "sub1") {
    eventStore.subImg1 = $event.target.files[0];
    subPrevUrl1.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "sub2") {
    eventStore.subImg2 = $event.target.files[0];
    subPrevUrl2.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "sub3") {
    eventStore.subImg3 = $event.target.files[0];
    subPrevUrl3.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "sub4") {
    eventStore.subImg4 = $event.target.files[0];
    subPrevUrl4.value = URL.createObjectURL($event.target.files[0]);
  }
  if (imgArea === "sub5") {
    eventStore.subImg5 = $event.target.files[0];
    subPrevUrl5.value = URL.createObjectURL($event.target.files[0]);
  }
};

async function createEvent() {
  if (eventStore.eventName === "") {
    alert("イベント名を入れてください");
    return false;
  }
  isLoading.value = true;

  const eventIdRef = collection(db, "events");
  const querySnapshot = await getDocs(eventIdRef);
  let id = 0;
  querySnapshot.forEach((doc) => {
    if (doc.id > id) {
      id = Number(doc.id);
    }
  });
  id++;
  try {
    const imgTitle = "events/" + id;
    if (eventStore.topImg1 != "") {
      eventStore.topImg1 = await saveEventsImg(
        imgTitle,
        "topImg1",
        eventStore.topImg1
      );
    }
    if (eventStore.topImg2 != "") {
      eventStore.topImg2 = await saveEventsImg(
        imgTitle,
        "topImg2",
        eventStore.topImg2
      );
    }

    if (eventStore.subImg1 != "") {
      eventStore.subImg1 = await saveEventsImg(
        imgTitle,
        "subImg1",
        eventStore.subImg1
      );
    }
    if (eventStore.subImg2 != "") {
      eventStore.subImg2 = await saveEventsImg(
        imgTitle,
        "subImg2",
        eventStore.subImg2
      );
    }
    if (eventStore.subImg3 != "") {
      eventStore.subImg3 = await saveEventsImg(
        imgTitle,
        "subImg3",
        eventStore.subImg3
      );
    }
    if (eventStore.subImg4 != "") {
      eventStore.subImg4 = await saveEventsImg(
        imgTitle,
        "subImg4",
        eventStore.subImg4
      );
    }
    if (eventStore.subImg5 != "") {
      eventStore.subImg5 = await saveEventsImg(
        imgTitle,
        "subImg5",
        eventStore.subImg5
      );
    }

    const today = new Date();
    setDoc(doc(db, "events", String(id)), {
      id,
      eventName: eventStore.eventName,
      organizerId: userStore.id,
      announceDate: eventStore.castAnnounceDate,
      openDate: eventStore.castOpenDate,
      closeDate: eventStore.castCloseDate,
      entryDate: eventStore.castEntryDate,
      genreId: eventStore.genreId,
      place: eventStore.place,
      postalcode: eventStore.postalcode,
      pref: eventStore.pref,
      municipalities: eventStore.municipalities,
      addressDtl: eventStore.addressDtl,
      priceSolo: eventStore.priceSolo,
      priceTeam: eventStore.priceTeam,
      priceDouble: eventStore.priceDouble,
      priceSpectating: eventStore.priceSpectating,
      topImg1: eventStore.topImg1,
      topImg2: eventStore.topImg2,
      subImg1: eventStore.subImg1,
      subImg2: eventStore.subImg2,
      subImg3: eventStore.subImg3,
      subImg4: eventStore.subImg4,
      subImg5: eventStore.subImg5,
      outline: eventStore.outline,
      contents: eventStore.contents,
      flgdel: eventStore.flgdel,
      workshop: eventStore.workshop,
      showcase: eventStore.showcase,
      battle: eventStore.battle,
      dateInsert: today.toLocaleString(),
      dateUpdate: today.toLocaleString(),
      entry: [],
    });

    isLoading.value = false;
    isCreate.value = true;
  } catch (error) {
    console.error("Error updating user info:", error);
  }
}

function handleClickCancel() {
  userStore.setUserMenu("event");
}
</script>

<template>
  <section id="isEvent" class="admin-event-create">
    <div class="ttl-wrap">
      <h1>イベント作成</h1>
    </div>
    <form class="l-form" @submit.prevent="createEvent">
      <div class="form-dtl">
        <label class="ttl">イベントタイトル</label>
        <input id="eventName" v-model="eventStore.eventName" type="text" />
      </div>
      <!-- Todoイベント画像置き場等考える -->
      <div class="form-dtl">
        <label class="ttl">
          トップ画像<span class="sub-txt">画像は2枚までです</span>
        </label>
        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'top1')"
        />
        <span v-if="topPrevUrl1" class="preview_zone">
          <img :src="topPrevUrl1" alt="ここにプレビューが表示されます" />
        </span>

        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'top2')"
        />
        <span v-if="topPrevUrl2" class="preview_zone">
          <img :src="topPrevUrl2" alt="ここにプレビューが表示されます" />
        </span>

        <label class="ttl">
          サブ画像<span class="sub-txt">画像は5枚までです</span>
        </label>
        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'sub1')"
        />
        <span v-if="subPrevUrl1" class="preview_zone">
          <img :src="subPrevUrl1" alt="ここにプレビューが表示されます" />
        </span>

        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'sub2')"
        />
        <span v-if="subPrevUrl2" class="preview_zone">
          <img :src="subPrevUrl2" alt="ここにプレビューが表示されます" />
        </span>

        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'sub3')"
        />
        <span v-if="subPrevUrl3" class="preview_zone">
          <img :src="subPrevUrl3" alt="ここにプレビューが表示されます" />
        </span>

        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'sub4')"
        />
        <span v-if="subPrevUrl4" class="preview_zone">
          <img :src="subPrevUrl4" alt="ここにプレビューが表示されます" />
        </span>

        <input
          type="file"
          accept="image/jpeg,image/png"
          @change="uploadFile($event, 'sub5')"
        />
        <span v-if="subPrevUrl5" class="preview_zone">
          <img :src="subPrevUrl5" alt="ここにプレビューが表示されます" />
        </span>
      </div>

      <div class="form-dtl">
        <label class="ttl">開催日時</label>
        <div class="form-flex">
          <div class="date-dtl">
            <VueDatePicker v-model="eventStore.openDate" />
          </div>
        </div>
      </div>
      <div class="form-dtl">
        <label class="ttl">終了日時</label>
        <div class="form-flex">
          <div class="date-dtl">
            <VueDatePicker v-model="eventStore.closeDate" />
          </div>
        </div>
      </div>
      <div class="form-dtl">
        <label class="ttl">告知日時</label>
        <div class="form-flex">
          <div class="date-dtl">
            <VueDatePicker v-model="eventStore.announceDate" />
          </div>
        </div>
      </div>

      <div class="form-dtl">
        <label class="ttl">場所</label>
        <div class="form-dtl place-dtl">
          <label>会場名</label>
          <input id="place" v-model="eventStore.place" type="textarea" />
        </div>
        <div class="form-dtl place-dtl">
          <label>郵便番号</label>
          <input
            id="postalcode"
            v-model="eventStore.postalcode"
            type="textarea"
            placeholder="123-45678"
          />
        </div>
        <div class="form-dtl place-dtl">
          <label>都道府県</label>
          <select id="pref" v-model="eventStore.pref" style="width: 100%">
            <option value="" selected>以下からお選びください</option>
            <!-- TODO:lintエラー消したい  -->
            <template v-for="(prefProps, index) in prefs" :key="index">
              <option :value="prefProps.id">
                {{ prefProps.kanji }}
              </option>
            </template>
          </select>
        </div>

        <div class="form-dtl place-dtl">
          <label>市区町村</label>
          <input
            id="municipalities"
            v-model="eventStore.municipalities"
            type="textarea"
            placeholder="市区町村をご入力ください"
          />
        </div>
        <div class="form-dtl">
          <label>住所詳細</label>
          <input
            id="addressDtl"
            v-model="eventStore.addressDtl"
            type="textarea"
            placeholder="丁目、番地、その他詳細住所をご入力ください"
          />
        </div>
      </div>
      <div class="form-dtl">
        <label class="ttl">値段</label>
        <label>Soloエントリー</label>
        <div class="entry-dtl">
          <input
            id="priceSolo"
            v-model="eventStore.priceSolo"
            type="textarea"
            placeholder="2000"
          /><span>円</span>
        </div>
        <label>Teamエントリー</label>
        <div class="entry-dtl">
          <input
            id="priceTeam"
            v-model="eventStore.priceTeam"
            type="textarea"
            placeholder="2000"
          /><span>円</span>
        </div>
        <label>ダブルエントリー</label>
        <div class="entry-dtl">
          <input
            id="priceDouble"
            v-model="eventStore.priceDouble"
            type="textarea"
            placeholder="1500"
          /><span>円</span>
        </div>
        <label>観戦費</label>
        <div class="entry-dtl">
          <input
            id="priceSpectating"
            v-model="eventStore.priceSpectating"
            type="textarea"
            placeholder="2000"
          /><span>円</span>
        </div>
      </div>
      <div class="form-dtl">
        <label class="ttl">概要</label>
        <input
          id="outline"
          v-model="eventStore.outline"
          type="textarea"
          placeholder="内容を入力してください"
        />
      </div>
      <div class="form-dtl">
        <label class="ttl">タグ</label>
        <ul class="ul-tag">
          <li class="tag-dtl">
            <input
              v-model="eventStore.workshop"
              class="checkbox"
              type="checkbox"
              value="whorkshop"
            />
            <p>Workshop</p>
          </li>
          <li class="tag-dtl">
            <input
              v-model="eventStore.showcase"
              class="checkbox"
              type="checkbox"
              value="showcase"
            />
            <p>Showcase</p>
          </li>
          <li class="tag-dtl">
            <input
              v-model="eventStore.battle"
              class="checkbox"
              type="checkbox"
              value="battle"
            />
            <p>Battle</p>
          </li>
        </ul>
      </div>
      <div class="form-dtl">
        <label class="ttl">エントリー内容</label>
        <label class="sub-ttl">エントリーフォーム用タイトル</label>
        <div>
          <div class="form-header">
            <button
              type="button"
              class="btn-add"
              @click="
                eventStore.addContent(
                  Object.keys(eventStore.contents).length + 1
                )
              "
            >
              追加
            </button>
          </div>
          <div
            v-for="(content, index, num) in eventStore.contents"
            :key="index"
            class="form-row"
          >
            <div>
              <label>コンテンツタイトル{{ num + 1 }}</label>
              <div>
                <input
                  :id="'title' + index"
                  :ref="'title' + index"
                  v-model="content.title"
                  type="text"
                  placeholder="例：アニソン solo battle"
                />
                <div>
                  <label>エントリー数</label>
                  <div>
                    <input
                      :id="'entry' + index"
                      :ref="'entry' + index"
                      v-model="content.entry"
                      type="number"
                      placeholder="32"
                    />
                    <select
                      :id="'type' + index"
                      :ref="'type' + index"
                      v-model="content.type"
                    >
                      <option value="solo" selected="true">名</option>
                      <option value="team">チーム</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            class="btn-del"
            @click="
              eventStore.delContent(Object.keys(eventStore.contents).length)
            "
          >
            ×
          </button>
        </div>
      </div>
      <div class="form-dtl">
        <label class="ttl">エントリー開始日時</label>
        <div class="form-flex">
          <div class="date-dtl">
            <VueDatePicker v-model="eventStore.entryDate" />
          </div>
        </div>
      </div>
      <div class="l-btn is-col2">
        <button
          class="btn-secondary"
          type="button"
          @click="handleClickCancel()"
        >
          キャンセル
        </button>
        <button class="btn-primary" type="button" @click="createEvent()">
          登録する
        </button>
      </div>
    </form>
    <eventModal :is-loading="isLoading" />
  </section>
</template>

<style scoped>
/* 最低限のstyle */
.date-picker {
  margin: 60px auto 0;
  width: 50%;
}
.preview_zone {
  border: 2px solid black;
  width: 400px;
  height: 500px;
  margin-right: 20px;
}
.btn-add {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #000000;
  color: #ffffff;
  border-style: none;
  border-radius: 3rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
.btn-del {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #000000;
  color: #ffffff;
  border-style: none;
  border-radius: 3rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@/assets/scss/page/adminEventCreate.scss";
</style>
