import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

// https://kikuchance.com/2022/05/19/firestore-onsnapshot-vue3/
// ルート変更時のログイン情報周り参考に作る

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "Apoppanet", desc: "Apoplanetトップ" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
