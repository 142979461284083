<script setup>
import { watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useUserStore } from "@/stores/user.js";

const router = useRouter();

const userStore = useUserStore();

const isNavActive = ref(false);
const isClicked = ref(false);
const flg = defineModel("flg", { default: false });
const isEvent = ref(false);
const isNotification = ref(false);
const isSetting = ref(false);

function toggleNavi() {
  isNavActive.value = !isNavActive.value;
  isClicked.value = !isClicked.value;
  var scrollpos;
  scrollpos = document.scrollingElement.scrollTop;
  document.body.classList.add("fixed");
  document.querySelector(".l-header-sp").classList.add("fixed");
  if (!flg.value) {
    document.querySelector(".fixed").style.top = -scrollpos + "px";
    flg.value = true;
  } else {
    document.body.classList.remove("fixed");
    window.scrollTo(0, scrollpos);
    flg.value = false;
  }
}

function toWhere(page) {
  var scrollpos;
  scrollpos = document.scrollingElement.scrollTop;
  document.body.classList.add("fixed");
  document.querySelector(".l-header-sp").classList.add("fixed");
  document.body.classList.remove("fixed");
  window.scrollTo(0, scrollpos);

  isNavActive.value = false;
  isClicked.value = false;
  flg.value = false;

  userStore.setUserMenu(page);
}

function logout() {
  isNavActive.value = false;
  isClicked.value = false;
  userStore.setUser(null);
}

watch(router.currentRoute, () => {
  switch (router) {
    case "/":
    case "/admin-event-create":
      isEvent.value = true;
      isNotification.value = false;
      isSetting.value = false;
      break;
    case "/admin-notification-list":
    case "/admin-notification-detail":
      isEvent.value = false;
      isNotification.value = true;
      isSetting.value = false;
      break;
    case "/admin-setting":
    case "/admin-setting-modify":
      isEvent.value = false;
      isNotification.value = false;
      isSetting.value = true;
      break;
    default:
      isEvent.value = false;
      isNotification.value = false;
      isSetting.value = false;
      break;
  }
});
</script>

<template>
  <div>
    <header id="admin-header" class="l-header">
      <div class="l-logo">
        <a class="lnk-logo" @click="toWhere('home')"
          ><img src="../assets/img/logo-w.svg" alt="Apoplanet" class="img-logo"
        /></a>
      </div>
      <div class="l-header-sp">
        <button
          id="hamburger"
          class="l-hamburger"
          :class="{ 'is-clicked': isClicked }"
          @click="toggleNavi()"
        >
          <span class="line" />
          <span class="line" />
        </button>
      </div>
      <nav
        id="admin-header-menu"
        class="l-header-menu"
        :class="{ 'is-nav-active': isNavActive }"
      >
        <div class="header-menu-dtl-user">
          <p class="username">
            {{ userStore.displayName }}<span class="auxiliaryname">さん</span>
          </p>
        </div>
        <div
          :class="['header-menu-dtl', { 'is-active': isEvent }]"
          role="button"
        >
          <div class="lnk-menu" @click="toWhere('event')">
            <svg class="icon-menu">
              <use
                xlink:href="../assets/img/symbol-defs.svg#icon-create-event"
              />
            </svg>
            イベント管理
          </div>
        </div>
        <div
          :class="['header-menu-dtl', { 'is-active': isNotification }]"
          role="button"
        >
          <div
            :class="['header-menu-dtl', { 'is-active': isSetting }]"
            role="button"
          >
            <div class="lnk-menu" @click="toWhere('home')">
              <svg class="icon-menu">
                <use xlink:href="../assets/img/symbol-defs.svg#icon-news" />
              </svg>
              お知らせ
            </div>
          </div>
        </div>
        <div
          :class="['header-menu-dtl', { 'is-active': isSetting }]"
          role="button"
        >
          <div class="lnk-menu" @click="toWhere('setting')">
            <svg class="icon-menu">
              <use xlink:href="../assets/img/symbol-defs.svg#icon-setting" />
            </svg>
            設定
          </div>
        </div>
        <div role="button" class="header-menu-dtl">
          <router-link class="lnk-menu" to="/" @click="logout">
            <svg class="icon-menu">
              <use xlink:href="../assets/img/symbol-defs.svg#icon-logout" />
            </svg>
            ログアウト
          </router-link>
        </div>
      </nav>
    </header>
    <div id="overRay" class="nav-overray" />
  </div>
</template>
