<script setup>
import { ref } from "vue";
import { Auth } from "@/functions/Auth.js";
import { auth } from "@/firebase/firebaseConfig";

// //userは親から渡ってきたやつ
// const user = defineModel();
const email = ref("");
const password = ref("");

import { useUserStore } from "@/stores/user.js";

const userStore = useUserStore();

async function handleClickLogin() {
  const userInfo = await Auth.login(auth, email.value, password.value);
  if (userInfo) {
    userStore.setUser(userInfo);
  }
}
</script>

<template>
  <section id="login" class="login">
    <div id="app" class="container pt-3">
      <div class="row">
        <form>
          <div class="l-form">
            <div class="form-dtl">
              <label class="ttl">メールアドレス</label>
              <input v-model="email" type="text" class="form-control" />
            </div>
            <div class="form-dtl">
              <label class="ttl">パスワード</label>
              <input v-model="password" type="password" class="form-control" />
            </div>
            <div class="l-btn">
              <button
                type="button"
                class="btn-primary"
                @click="handleClickLogin"
              >
                ログイン
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
