<script setup>
import { useUserStore } from "@/stores/user.js";
import EventList from "@/views/event/EventList.vue";

const userStore = useUserStore();
function handleClick() {
  userStore.setUserMenu("eventCreate");
}
</script>

<template>
  <section id="isEvent" class="admin-event">
    <div class="ttl-wrap">
      <h1>イベント管理</h1>
      <div role="button" class="l-btn">
        <button class="btn-primary" @click="handleClick">イベント作成</button>
      </div>
    </div>
    <table class="l-event-list">
      <Suspense>
        <EventList />
        <template #fallback> Loading... </template>
      </Suspense>
    </table>
  </section>
</template>

<style lang="scss">
@import "@/assets/scss/page/adminEvent.scss";
</style>
