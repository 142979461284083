import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    id: "",
    name: "",
    menu: "home",
    displayName: "",
    email: "",
    organizerName: "",
    represent: "",
  }),

  actions: {
    setUser(user) {
      if (user) {
        this.id = user.uid;
        this.name = user.name;
        this.displayName = user.displayName;
        this.email = user.email;
      } else {
        this.id = "";
        this.name = "";
        this.displayName = "";
        this.email = "";
      }
    },
    setUserMenu(menu) {
      this.menu = menu;
    },
  },
});
