export const prefs = [
  {
    id: "1",
    kanji: "北海道",
    yomi: "ほっかいどう",
    roma: "hokkaido",
  },
  {
    id: "2",
    kanji: "青森県",
    yomi: "あおもり",
    roma: "aomori",
  },
  {
    id: "3",
    kanji: "岩手県",
    yomi: "いわて",
    roma: "iwate",
  },
  {
    id: "4",
    kanji: "宮城県",
    yomi: "みやぎ",
    roma: "miyagi",
  },
  {
    id: "5",
    kanji: "秋田県",
    yomi: "あきた",
    roma: "akita",
  },
  {
    id: "6",
    kanji: "山形県",
    yomi: "やまがた",
    roma: "yamagata",
  },
  {
    id: "7",
    kanji: "福島県",
    yomi: "ふくしま",
    roma: "fukushima",
  },
  {
    id: "8",
    kanji: "茨城県",
    yomi: "いばらき",
    roma: "ibaraki",
  },
  {
    id: "9",
    kanji: "栃木県",
    yomi: "とちぎ",
    roma: "tochigi",
  },
  {
    id: "10",
    kanji: "群馬県",
    yomi: "ぐんま",
    roma: "gunma",
  },
  {
    id: "11",
    kanji: "埼玉県",
    yomi: "さいたま",
    roma: "saitama",
  },
  {
    id: "12",
    kanji: "千葉県",
    yomi: "ちば",
    roma: "chiba",
  },
  {
    id: "13",
    kanji: "東京都",
    yomi: "とうきょう",
    roma: "tokyo",
  },
  {
    id: "14",
    kanji: "神奈川県",
    yomi: "かながわ",
    roma: "kanagawa",
  },
  {
    id: "15",
    kanji: "新潟県",
    yomi: "にいがた",
    roma: "niigata",
  },
  {
    id: "16",
    kanji: "富山県",
    yomi: "とやま",
    roma: "toyama",
  },
  {
    id: "17",
    kanji: "石川県",
    yomi: "いしかわ",
    roma: "ishikawa",
  },
  {
    id: "18",
    kanji: "福井県",
    yomi: "ふくい",
    roma: "fukui",
  },
  {
    id: "19",
    kanji: "山梨県",
    yomi: "やまなし",
    roma: "yamanashi",
  },
  {
    id: "20",
    kanji: "長野県",
    yomi: "ながの",
    roma: "nagano",
  },
  {
    id: "21",
    kanji: "岐阜県",
    yomi: "ぎふ",
    roma: "gifu",
  },
  {
    id: "22",
    kanji: "静岡県",
    yomi: "しずおか",
    roma: "shizuoka",
  },
  {
    id: "23",
    kanji: "愛知県",
    yomi: "あいち",
    roma: "aichi",
  },
  {
    id: "24",
    kanji: "三重県",
    yomi: "みえ",
    roma: "mie",
  },
  {
    id: "25",
    kanji: "滋賀県",
    yomi: "しが",
    roma: "shiga",
  },
  {
    id: "26",
    kanji: "京都府",
    yomi: "きょうと",
    roma: "kyoto",
  },
  {
    id: "27",
    kanji: "大阪府",
    yomi: "おおさか",
    roma: "osaka",
  },
  {
    id: "28",
    kanji: "兵庫県",
    yomi: "ひょうご",
    roma: "hyogo",
  },
  {
    id: "29",
    kanji: "奈良県",
    yomi: "なら",
    roma: "nara",
  },
  {
    id: "30",
    kanji: "和歌山県",
    yomi: "わかやま",
    roma: "wakayama",
  },
  {
    id: "31",
    kanji: "鳥取県",
    yomi: "とっとり",
    roma: "tottori",
  },
  {
    id: "32",
    kanji: "島根県",
    yomi: "しまね",
    roma: "shimane",
  },
  {
    id: "33",
    kanji: "岡山県",
    yomi: "おかやま",
    roma: "okayama",
  },
  {
    id: "34",
    kanji: "広島県",
    yomi: "ひろしま",
    roma: "hiroshima",
  },
  {
    id: "35",
    kanji: "山口県",
    yomi: "やまぐち",
    roma: "yamaguchi",
  },
  {
    id: "36",
    kanji: "徳島県",
    yomi: "とくしま",
    roma: "tokushima",
  },
  {
    id: "37",
    kanji: "香川県",
    yomi: "かがわ",
    roma: "kagawa",
  },
  {
    id: "38",
    kanji: "愛媛県",
    yomi: "えひめ",
    roma: "ehime",
  },
  {
    id: "39",
    kanji: "高知県",
    yomi: "こうち",
    roma: "kochi",
  },
  {
    id: "40",
    kanji: "福岡県",
    yomi: "ふくおか",
    roma: "fukuoka",
  },
  {
    id: "41",
    kanji: "佐賀県",
    yomi: "さが",
    roma: "saga",
  },
  {
    id: "42",
    kanji: "長崎県",
    yomi: "ながさき",
    roma: "nagasaki",
  },
  {
    id: "43",
    kanji: "熊本県",
    yomi: "くまもと",
    roma: "kumamoto",
  },
  {
    id: "44",
    kanji: "大分県",
    yomi: "おおいた",
    roma: "oita",
  },
  {
    id: "45",
    kanji: "宮崎県",
    yomi: "みやざき",
    roma: "miyazaki",
  },
  {
    id: "46",
    kanji: "鹿児島県",
    yomi: "かごしま",
    roma: "kagoshima",
  },
  {
    id: "47",
    kanji: "沖縄県",
    yomi: "おきなわ",
    roma: "okinawa",
  },
];
