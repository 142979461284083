import { defineStore } from "pinia";

export const useEventStore = defineStore("event", {
  state: () => ({
    openDate: "",
    closeDate: "",
    entryDate: "",
    announceDate: "",
    eventName: "",
    organizerId: "",
    genreId: "",
    place: "",
    postalcode: "",
    pref: "",
    municipalities: "",
    addressDtl: "",
    priceSolo: "",
    priceTeam: "",
    priceDouble: "",
    priceSpectating: "",
    topImg1: "",
    topImg2: "",
    subImg1: "",
    subImg2: "",
    subImg3: "",
    subImg4: "",
    subImg5: "",
    outline: "",
    flgdel: "",
    workshop: false,
    showcase: false,
    battle: false,
    isEnter1: false,
    isEnter2: false,
    isEnter3: false,
    isEnter4: false,
    isEnter5: false,
    isEnter6: false,
    isEnter7: false,
    contents: { content1: { title: "", entry: 0, type: "" } },
  }),
  getters: {
    // date系はタイムスタンプからstringに変換
    castAnnounceDate: (state) => state.announceDate.toLocaleString(),
    castEntryDate: (state) => state.entryDate.toLocaleString(),
    castOpenDate: (state) => state.openDate.toLocaleString(),
    castCloseDate: (state) => state.closeDate.toLocaleString(),
  },
  actions: {
    addContent(index) {
      this.contents[`content${index}`] = {
        title: "",
        entry: 0,
        type: "",
      };
    },
    delContent(index) {
      delete this.contents[`content${index}`];
    },
    setEvent(event) {
      this.openDate = event.openDate;
      this.closeDate = event.closeDate;
      this.entryDate = event.entryDate;
      this.announceDate = event.announceDate;
      this.eventName = event.eventName;
      this.organizerId = event.organizerId;
      this.genreId = event.genreId;
      this.place = event.place;
      this.postalcode = event.postalcode;
      this.pref = event.pref;
      this.municipalities = event.municipalities;
      this.addressDtl = event.addressDtl;
      this.priceSolo = event.priceSolo;
      this.priceTeam = event.priceTeam;
      this.priceDouble = event.priceDouble;
      this.priceSpectating = event.priceSpectating;
      this.topImg1 = event.topImg1;
      this.topImg2 = event.topImg2;
      this.subImg1 = event.subImg1;
      this.subImg2 = event.subImg2;
      this.subImg3 = event.subImg3;
      this.subImg4 = event.subImg4;
      this.subImg5 = event.subImg5;
      this.outline = event.outline;
      this.flgdel = event.flgdel;
      this.workshop = event.workshop;
      this.showcase = event.showcase;
      this.battle = event.battle;
      this.contents = event.contents;
    },
  },
});
