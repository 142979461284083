import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyASzkjJrsREtQRtEbEj9eKuYG9xz7tDdg0",
  authDomain: "apopplanetadmin-dev.firebaseapp.com",
  projectId: "apopplanetadmin-dev",
  storageBucket: "apopplanetadmin-dev.appspot.com",
  messagingSenderId: "379826657712",
  appId: "1:379826657712:web:32c129cba4e1f936961e75",
  measurementId: "G-PV5919215D",
};
const firebase = initializeApp(firebaseConfig);

export default firebase;
export const auth = getAuth(firebase);
export const db = getFirestore(firebase);
