import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";

export const saveEventsImg = async (title, memo, fileData) => {
  const metadata = {
    contentType: "image/jpeg",
  };
  const storage = getStorage();
  const imageRef = ref(storage, title + "/" + memo);
  const imgUrl = await uploadBytesResumable(imageRef, fileData, metadata)
    .then(async (snapshot) => {
      const url = await getDownloadURL(snapshot.ref);
      return url;
    })
    .catch((error) => {
      console.error("Upload failed", error);
    });
  return imgUrl;
};
