<script setup>
import { db } from "@/firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { useUserStore } from "@/stores/user.js";

// store呼び出し
const userStore = useUserStore();

const userRef = doc(db, "users", userStore.id);
const userSnap = await getDoc(userRef);
if (!userSnap.exists) {
  console.log("No such document!");
} else {
  userStore.organizerName = userSnap.data().organizerName;
  userStore.represent = userSnap.data().represent;
  userStore.tel = userSnap.data().tel;
}

function handleClickModify() {
  userStore.setUserMenu("settingModify");
}
</script>
<template id="isSetting">
  <section id="isSetting" class="admin-setting">
    <div class="ttl-wrap">
      <h1>設定</h1>
    </div>
    <ul class="ls-setting">
      <li>
        <h2 class="ttl">名前</h2>
        <p class="txt">
          {{ userStore.displayName }}
        </p>
      </li>
      <li>
        <h2 class="ttl">オーガナイザー名</h2>
        <p class="txt">
          {{ userStore.organizerName }}
        </p>
      </li>
      <li>
        <h2 class="ttl">レペゼン</h2>
        <p class="txt">
          {{ userStore.represent }}
        </p>
      </li>
      <li>
        <h2 class="ttl">電話番号</h2>
        <p class="txt">
          {{ userStore.tel }}
        </p>
      </li>
      <li>
        <h2 class="ttl">メールアドレス</h2>
        <p class="txt">
          {{ userStore.email }}
        </p>
      </li>
      <li class="l-btn">
        <button type="button" class="btn-primary" @click="handleClickModify()">
          編集する
        </button>
      </li>
    </ul>
  </section>
</template>
<style lang="scss">
@import "@/assets/scss/page/adminSetting.scss";
</style>
