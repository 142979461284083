<script setup>
const props = defineProps({ isLoading: Boolean });
</script>

<template>
  <Transition name="modal">
    <div v-if="props.isLoading" class="modal-mask">
      <div class="modal-container">
        <div class="modal-body">
          <slot name="body">保存中 </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style lang="scss">
@import "@/assets/scss/page/adminSettingModifyModal.scss";
</style>
