<script setup>
import { useUserStore } from "@/stores/user.js";
import { db } from "@/firebase/firebaseConfig";
import { collection, getDocs, query, where, limit } from "firebase/firestore";

const userStore = useUserStore();

const getSubCollection = async () => {
  const q = query(
    collection(db, "events"),
    where("organizerId", "==", userStore.id),
    limit(10000)
  );
  let eventList = new Array();
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    eventList.push(doc.data());
  });
  eventList.sort((first, secound) => (first.id < secound.id ? 1 : -1));

  return eventList;
};
const list = await getSubCollection().then((data) => data);

const goToEdit = (to) => {
  userStore.setUserMenu("eventEdit" + "/" + to);
};
</script>

<template>
  <tbody style="overflow: auto">
    <tr class="event-titles">
      <th>イベント名</th>
      <th>場所</th>
      <th>開催日時</th>
      <th>公開開始日時</th>
      <th>エントリー開始日時</th>
      <th>タグ</th>
    </tr>
    <tr
      v-for="(event, index) in list"
      :key="index"
      class="event-contents"
      @click="goToEdit(event.id)"
    >
      <td class="event-title" data-title="イベント名">
        <div>{{ event.eventName }}</div>
      </td>
      <td class="event-place" data-title="場所">
        <div>{{ event.place }}</div>
      </td>
      <td class="event-date-hold" data-title="開催日時">
        {{ event.openDate }}~{{ event.closeDate }}
      </td>
      <td class="event-date-release" data-title="公開開始日時">
        {{ event.announceDate }}
      </td>
      <td class="event-date-start-entry" data-title="エントリー開始日時">
        {{ event.entryDate }}
      </td>
      <td class="event-tag" data-title="タグ">
        <span v-if="event.showcase" class="tag showcase">showcase</span>
        <span v-if="event.workshop" class="tag workshop">workshop</span>
        <span v-if="event.battle" class="tag battle">Battle</span>
      </td>
    </tr>
  </tbody>
</template>
<style lang="scss">
@import "@/assets/scss/page/adminEvent.scss";
</style>
