<script setup>
import AdminHeader from "@/components/AdminHeader.vue";
import AdminLogin from "@/views/login/AdminLogin.vue";
import HomeMenu from "@/components/HomeMemu.vue";
import { useUserStore } from "@/stores/user.js";

const userStore = useUserStore();
</script>

<template>
  <div v-if="userStore.id === ''">
    <div class="l-content" />
    <div>ログインしてください</div>
    <AdminLogin />
  </div>
  <div v-else>
    <div class="l-content">
      <AdminHeader />
    </div>
    <div class="main-content">
      <HomeMenu />
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/style.scss";
@import "@/assets/scss/page/adminApp.scss";
</style>
