import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueGtag from "vue-gtag";
// Vue Datepickerに関するimport
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { createPinia } from "pinia";

const app = createApp(App);
app.use(router);
app.use(VueGtag, { config: { id: "G-Y5NGZFYMCZ" } });
app.use(createPinia());

app.component("VueDatePicker", VueDatePicker);

app.mount("#app");
